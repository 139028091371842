import Vue from 'vue'

export default {
  state: {
    darkMode: false,
    tableView: false,
    userStats: true,
    notHunted: false,
    highlightNewSpots: false,
    callsign: '',
    bandFilter: 'All',
    modeFilter: 'All',
    programFilter: 'All',
    qrtFilter: 'Show',
    huntedFilter: 'Show',
    spotSort: 'Time',
    activationsPeriod: 365,
    activationsSort: 'Time',
    showRbnInHistory: true
  },

  mutations: {
    SET_DARK_MODE (state, darkMode) {
      state.darkMode = darkMode;
      Vue.$cookies.set('POTA_SETTINGS', state, '1y');
    },
    SET_TABLE_VIEW (state, tableView) {
      state.tableView = tableView;
      Vue.$cookies.set('POTA_SETTINGS', state, '1y');
    },
    SET_USER_STATS(state, userStats) {
      state.userStats = userStats;
      Vue.$cookies.set('POTA_SETTINGS', state, '1y');
    },
    SET_NOT_HUNTED(state, notHunted) {
      state.notHunted = notHunted;
      Vue.$cookies.set('POTA_SETTINGS', state, '1y');
    },
    SET_HIGHLIGHT_NEW_SPOTS(state, highlightNewSpots) {
      state.highlightNewSpots = highlightNewSpots;
      Vue.$cookies.set('POTA_SETTINGS', state, '1y');
    },
    SET_CALLSIGN (state, callsign) {
      state.callsign = callsign;
      Vue.$cookies.set('POTA_SETTINGS', state, '1y');
    },
    SET_BAND_FILTER (state, filter) {
      if (filter == null)
        filter = 'All';
      state.bandFilter = filter;
      Vue.$cookies.set('POTA_SETTINGS', state, '1y');
    },
    SET_MODE_FILTER (state, filter) {
      if (filter == null)
        filter == 'All';
      state.modeFilter = filter;
      Vue.$cookies.set('POTA_SETTINGS', state, '1y');
    },
    SET_PROGRAM_FILTER (state, filter) {
      if (filter == null)
        filter == 'All';
      state.programFilter = filter;
      Vue.$cookies.set('POTA_SETTINGS', state, '1y');
    },
    SET_QRT_FILTER (state, filter) {
      if (filter == null)
        filter == 'Show';
      state.qrtFilter = filter;
      Vue.$cookies.set('POTA_SETTINGS', state, '1y');
    },
    SET_HUNTED_FILTER (state, filter) {
      if (filter == null)
        filter == 'Show';
      state.huntedFilter = filter;
      Vue.$cookies.set('POTA_SETTINGS', state, '1y');
    },
    SET_SORT (state, sort) {
      if (sort == null)
        sort == 'Time';
      state.spotSort = sort;
      Vue.$cookies.set('POTA_SETTINGS', state, '1y');
    },
    SET_ACTIVATIONS_PERIOD (state, days) {
      if (days == null)
        days == 365;
      state.activationsPeriod = days;
      Vue.$cookies.set('POTA_SETTINGS', state, '1y');
    },
    SET_ACTIVATIONS_SORT (state, sort) {
      if (sort == null)
        sort == 'Start Date/Time';
      state.activationsSort = sort;
      Vue.$cookies.set('POTA_SETTINGS', state, '1y');
    },
    SET_SHOW_RBN (state, showRbn) {
      state.showRbnInHistory = showRbn;
      Vue.$cookies.set('POTA_SETTINGS', state, '1y');
    },
  },

  actions: {
    setDarkMode ({ commit }) {
      commit('SET_DARK_MODE')
    },
    setTableView ({ commit }) {
      commit('SET_TABLE_VIEW')
    },
    setUserStats ({ commit }) {
      commit('SET_USER_STATS')
    },
    setShowNotHunted ({ commit }) {
      commit('SET_NOT_HUNTED')
    },
    setHighlightNewSpots ({ commit }) {
      commit('SET_HIGHLIGHT_NEW_SPOTS')
    },
    setCallsign ({ commit }) {
      commit('SET_CALLSIGN')
    }
  }
}
