import axios from 'axios'
import dayjs from 'dayjs'
import adif from './adif'
import user from './user'

export default {
  state: {
    spots: [],
    filtered: [],
    loading: true,
    bandFilter: 'All',
    modeFilter: 'All',
    programFilter: 'All',
    huntedFilter: 'Show',
    qrtFilter: 'Show',
    sort: 'Time',
    hunted: [],
    pause: false
  },

  mutations: {
    SET_SPOTS (state, spots) {
      if (spots == null)
        spots = []

      var utcNow = dayjs.utc()

      var callsigns = []

      if (user.state.isAuthenticated)
      {
          callsigns = user.state.callsigns
          if (!callsigns)
            callsigns = []
      }

      // augment spots with band and mode data
      for (var i = 0; i < spots.length; i++)
      {
        var spot = spots[i]

        spot.date = dayjs.utc(spot.spotTime)
        spot.age = utcNow.diff(spot.date, 'seconds')
        if (spot.age < 0)
          spot.age = 0

        // key used for table rows (calculate once here since it is used so many times)
        spot.key = `${spot.spotId}_${spot.age}`

        // clean up extra spaces from those knuckleheads over at pota.us
        spot.activator = spot.activator.trim()
        spot.spotter = spot.spotter.trim()
        spot.reference = spot.reference.trim()

        // expect frequency in kHz; band list is MHz
        var freq = spot.frequency / 1000.0
        var bands = adif.state.bands.filter(x => !x.hide && x.low <= freq && x.high >= freq)
        if (bands != null && bands.length > 0)
          spot.band = bands[0].band.toUpperCase()
        else
          spot.band = 'Other'

        if (spot.mode == null || spot.mode == '')
          spot.mode = 'SSB'

        spot.mode = spot.mode.trim()

        if (state.hunted)
          spot.hunted = state.hunted.some(x => x.activator == spot.activator && x.reference == spot.reference && x.band.toUpperCase() == spot.band && x.mode == spot.mode)

        var parts = spot.reference.split('-')
        spot.program = parts[0]

        // add space after commas so that the column can wrap in table view
        if (spot.locationDesc)
          spot.locationDesc = spot.locationDesc.replace(',', ', ')
        else
          spot.locationDesc = ''

        spot.qrt = false;
        if (spot.comments != null && spot.comments.toUpperCase().includes('QRT'))
          spot.qrt = true;

        spot.rbn = false;
        if (spot.comments != null && spot.comments.toUpperCase().includes('RBN'))
          spot.rbn = true;

        spot.user = callsigns.includes(spot.activator);
      }

      state.spots = spots
      state.loading = false

      this.dispatch('updateFilteredSpots')
    },
    SET_HUNTED (state, hunted) {
      state.hunted = hunted

      var spots = state.spots

      for (var i = 0; i < spots.length; i++)
      {
        var spot = spots[i]

        if (state.hunted)
          spot.hunted = state.hunted.some(x => x.activator == spot.activator && x.reference == spot.reference && x.band.toUpperCase() == spot.band && x.mode == spot.mode)
        else
          spot.hunted = false

        var parts = spot.reference.split('-')
        spot.program = parts[0]
      }

      this.dispatch('updateFilteredSpots')
    },
    SET_BAND_FILTER (state, filter) {
      if (filter == null)
        filter = 'All';
      state.bandFilter = filter

      this.dispatch('updateFilteredSpots')
    },
    SET_MODE_FILTER (state, filter) {
      if (filter == null)
        filter = 'All';
      state.modeFilter = filter

      this.dispatch('updateFilteredSpots')
    },
    SET_PROGRAM_FILTER (state, filter) {
      if (filter == null)
        filter = 'All';
      state.programFilter = filter

      this.dispatch('updateFilteredSpots')
    },
    SET_HUNTED_FILTER (state, filter) {
      if (filter == null)
        filter = 'Show';
      state.huntedFilter = filter

      this.dispatch('updateFilteredSpots')
    },
    SET_QRT_FILTER (state, filter) {
      if (filter == null)
        filter = 'Show';
      state.qrtFilter = filter

      this.dispatch('updateFilteredSpots')
    },
    SET_SORT (state, sort) {
      if (sort == null)
        sort = 'Time';
      state.sort = sort

      this.dispatch('updateFilteredSpots')
    },
    SET_FILTERED_SPOTS (state, spots) {
      if (spots == null)
        spots = [];
      state.filtered = spots
    },
    START_LOADING (state) {
      state.loading = true
    }
  },

  actions: {
    updateFilteredSpots({ commit, rootState }) {
      var state = rootState.spots
      var spots = Array.from(state.spots)

      /*if (rootState.user.isAuthenticated)
      {
          var callsigns = rootState.user.callsigns
          if (!callsigns)
            callsigns = []

          for (var i = 0; i < spots.length; i++)
          {
            spots[i].user = callsigns.includes(spots[i].activator)
          }
      }*/

      if (state.bandFilter != 'All' && state.bandFilter != null)
        spots = spots.filter(x => x.user || x.band == state.bandFilter)

      if (state.modeFilter != 'All' && state.modeFilter != null && state.modeFilter != 'DATA' && state.modeFilter != 'PHONE')
        spots = spots.filter(x => x.user || x.mode == state.modeFilter)
      
      if (state.modeFilter == 'DATA')
        spots = spots.filter(x => x.user || adif.state.dataModes.includes(x.mode))
      
      if (state.modeFilter == 'PHONE')
        spots = spots.filter(x => x.user || adif.state.phoneModes.includes(x.mode))

      if (state.programFilter != 'All' && state.programFilter != null)
        spots = spots.filter(x => x.user || x.program == state.programFilter)

      if (state.huntedFilter != 'Show' && state.huntedFilter != null)
        spots = spots.filter(x => x.user || !x.hunted)

      if (state.qrtFilter != 'Show' && state.qrtFilter != null)
        spots = spots.filter(x => x.user || !x.qrt)

      // default sort is most recent spot time with QRT at bottom of list

      if (state.sort == 'Activator')
        spots.sort((a,b) => {
          if (a.user != b.user)
            return a.user ? -1 : 1;
          if (a.qrt != b.qrt)
            return a.qrt ? 1 : -1;
          return a.activator.localeCompare(b.activator)
        })
      else if (state.sort == 'Frequency')
        spots.sort((a,b) => {
          if (a.user != b.user)
            return a.user ? -1 : 1;
          if (a.qrt != b.qrt)
            return a.qrt ? 1 : -1;
          return a.frequency.localeCompare(b.frequency, undefined, {numeric: true})
        })
      else if (state.sort == 'Mode')
        spots.sort((a,b) => {
          if (a.user != b.user)
            return a.user ? -1 : 1;
          if (a.qrt != b.qrt)
            return a.qrt ? 1 : -1;
          if (a.mode != b.mode)
            return a.mode.localeCompare(b.mode)
          return a.frequency.localeCompare(b.frequency, undefined, {numeric: true})
        })
      else if (state.sort == 'Reference')
        spots.sort((a,b) => {
          if (a.user != b.user)
            return a.user ? -1 : 1;
          if (a.qrt != b.qrt)
            return a.qrt ? 1 : -1;
          return a.reference.localeCompare(b.reference)
        })
      else if (state.sort == 'Park Name')
        spots.sort((a,b) => {
          if (a.user != b.user)
            return a.user ? -1 : 1;
          if (a.qrt != b.qrt)
            return a.qrt ? 1 : -1;
          return a.name.localeCompare(b.name)
        })
      else if (state.sort == 'Region')
        spots.sort((a,b) => {
          if (a.user != b.user)
            return a.user ? -1 : 1;
          if (a.qrt != b.qrt)
            return a.qrt ? 1 : -1;
          return a.locationDesc.localeCompare(b.locationDesc)
        })
      else if (state.sort == 'Spotter')
        spots.sort((a,b) => {
          if (a.user != b.user)
            return a.user ? -1 : 1;
          if (a.qrt != b.qrt)
            return a.qrt ? 1 : -1;
          return a.spotter.localeCompare(b.spotter)
        })
      else if (state.sort == 'Time')
        spots.sort((a,b) => {
          if (a.user != b.user)
            return a.user ? -1 : 1;
          if (a.qrt != b.qrt)
            return a.qrt ? 1 : -1;
          return a.date.isAfter(b.date) ? -1 : 1;
        })

      commit('SET_FILTERED_SPOTS', spots)
    },
    getSpots ({ commit }) {
      commit('START_LOADING')
      axios
        .get(`https://${process.env.VUE_APP_API_URL}/spot/activator`)
        .then(response => response.data)
        .then(json => commit('SET_SPOTS', json))
    },
    getHunted ({ commit, rootState }) {
      if (rootState.user.callsign == null)
        return;

      axios
        .get(`https://${process.env.VUE_APP_API_URL}/spot/hunted/${rootState.user.callsign}`)
        .then(response => response.data)
        .then(json => commit('SET_HUNTED', json))
    },
    addHunted ({ commit, rootState }, spot) {
      var band = 'Other'
      // expect frequency in kHz
      var freq = spot.frequency / 1000.0
      var bands = rootState.adif.bands.filter(x => !x.hide && x.low <= freq && x.high >= freq)
      if (bands != null && bands.length > 0)
        band = bands[0].band.toUpperCase()

      var hunted = Array.from(rootState.spots.hunted)

      var existing = hunted.filter(x =>
        x.activator == spot.activator &&
        x.reference == spot.reference &&
        x.band == band &&
        x.mode == spot.mode)

      if (existing.length == 0)
      {
        hunted.push({
          activator: spot.activator,
          reference: spot.reference,
          band: band,
          mode: spot.mode
        })

        commit('SET_HUNTED', hunted)
      }
    },
    resetHunted({ commit }) {
      // called at the start of each UTC day to reset hunted status
      commit('SET_HUNTED', [])
    }
  }
}
