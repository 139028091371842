<template>
    <v-text-field v-model="reference" @keyup="keypress" :messages="messages" :error-messages="errors" :rules="rules" :required="required" v-bind="$attrs"></v-text-field>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        value: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        state: null
    },
    data () {
        return {
            messages: null,
            errors: null,
            rules: [
                v => this.errors == null || this.errors.length == 0,
                v => (!this.required || v.length > 0) || 'Example: US-1234'
            ],
        }
    },
    computed: {
        reference: {
            get() { return this.value },
            set(reference) {this.$emit('input', reference)}
        }
    },
    mounted: function() {
        this.keypress(this.reference);
    },
    watch: {
        value(newValue, oldValue) {
            if (oldValue != newValue)
                this.keypress(newValue);
        }
    },
    methods: {
        keypress(event) {
            if (event.keyCode == 13) {
                this.$emit('submit', null, this.state)
                return
            }

            var value = this.reference
            var reference = value.toUpperCase();

            if (/(K-TEST)|(([A-Z\d]{1,2})-(\d{4,}))/.test(reference))
            {
                this.errors = null
                this.messages = null
                this.reference = reference
                this.lookup(reference)
                return;
            }
            else
            {
                if (this.required && (!value || value.length == 0))
                {
                    this.errors = ['Example: US-1234']
                    this.message = null
                }
                else
                {
                    this.messages = ['Example: US-1234']
                    this.errors = null
                }
                this.$emit('info', null, this.state)
            }
        },
        lookup(value) {
            this.messages = ['...loading...']

            axios.get(`https://${process.env.VUE_APP_API_URL}/park/${value}`)
                .then((response) => {
                    var info = response.data
                    if (info)
                    {
                        if (info.active == 0 && info.reference != 'K-TEST')
                        {
                            this.errors = ['Inactive Park']
                            this.messages = null
                        }
                        else
                        {
                            this.messages = [`${info.name} ${info.parktypeDesc}`]
                            this.errors = null
                            this.$emit('info', info, this.state)
                        }
                    }
                    else
                    {
                        this.messages = null
                        this.errors = ['No park found for reference ' + value]
                        this.$emit('info', null, this.state)
                    }
                })
                // eslint-disable-next-line
                .catch(error => {
                    this.errors = ['Unable to lookup park name']
                    this.messages = null
                })
        }
    },
}
</script>
