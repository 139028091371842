var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.menu && !_vm.card)?_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"slot":"activator","color":_vm.buttoncolor},slot:"activator"},on),[_vm._v("Add Activation")]):(_vm.card)?_c('v-card',_vm._g({staticClass:"activation-card",attrs:{"flat":"","shaped":""}},on),[_c('v-card-text',[_c('h1',[_vm._v("Will you be at a park later?")]),_c('v-icon',{attrs:{"size":"100px"}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.mdiPlus))]),_c('h1',[_vm._v("Click here to add your activation!")])],1)],1):_c('v-list-item',_vm._g({},on),[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(_vm.$vuetify.icons.values.mdiCalendarPlus))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Add Activation")])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline green white--text",attrs:{"primary-title":""}},[_c('span',[_vm._v(_vm._s(_vm.title))])]),_c('v-card-text',[_c('v-form',{ref:"formActivation",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-select',{attrs:{"label":"Callsign","items":_vm.user_callsigns,"return-object":""},model:{value:(_vm.activator),callback:function ($$v) {_vm.activator=$$v},expression:"activator"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('park-reference-entry',{attrs:{"label":"Reference","required":""},model:{value:(_vm.reference),callback:function ($$v) {_vm.reference=$$v},expression:"reference"}})],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date (UTC)","prepend-icon":_vm.$vuetify.icons.values.mdiCalendar,"readonly":"","rules":_vm.startdateRules},model:{value:(_vm.startdate),callback:function ($$v) {_vm.startdate=$$v},expression:"startdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuStartDate),callback:function ($$v) {_vm.menuStartDate=$$v},expression:"menuStartDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuStartDate = false}},model:{value:(_vm.startdate),callback:function ($$v) {_vm.startdate=$$v},expression:"startdate"}})],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-menu',{ref:"menuStartTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.starttime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.starttime=$event},"update:return-value":function($event){_vm.starttime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Time (UTC)","prepend-icon":_vm.$vuetify.icons.values.mdiClockTimeFourOutline,"readonly":"","rules":_vm.starttimeRules},model:{value:(_vm.starttime),callback:function ($$v) {_vm.starttime=$$v},expression:"starttime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuStartTime),callback:function ($$v) {_vm.menuStartTime=$$v},expression:"menuStartTime"}},[(_vm.menuStartTime)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menuStartTime.save(_vm.starttime)}},model:{value:(_vm.starttime),callback:function ($$v) {_vm.starttime=$$v},expression:"starttime"}}):_vm._e()],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date (UTC)","prepend-icon":_vm.$vuetify.icons.values.mdiCalendar,"readonly":"","rules":_vm.enddateRules},model:{value:(_vm.enddate),callback:function ($$v) {_vm.enddate=$$v},expression:"enddate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuEndDate),callback:function ($$v) {_vm.menuEndDate=$$v},expression:"menuEndDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuEndDate = false}},model:{value:(_vm.enddate),callback:function ($$v) {_vm.enddate=$$v},expression:"enddate"}})],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-menu',{ref:"menuEndTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.endtime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.endtime=$event},"update:return-value":function($event){_vm.endtime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Time (UTC)","prepend-icon":_vm.$vuetify.icons.values.mdiClockTimeFourOutline,"readonly":"","rules":_vm.endtimeRules},model:{value:(_vm.endtime),callback:function ($$v) {_vm.endtime=$$v},expression:"endtime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuEndTime),callback:function ($$v) {_vm.menuEndTime=$$v},expression:"menuEndTime"}},[(_vm.menuEndTime)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.menuEndTime.save(_vm.endtime)}},model:{value:(_vm.endtime),callback:function ($$v) {_vm.endtime=$$v},expression:"endtime"}}):_vm._e()],1)],1),_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-text-field',{attrs:{"label":"Frequency"},model:{value:(_vm.frequency),callback:function ($$v) {_vm.frequency=$$v},expression:"frequency"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"label":"Comments"},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"green white--text"},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }