<template>
    <v-alert border="left" color="error" outlined class="admin-info" v-if="isUserAdminRoute">
        <h1 v-if="info">{{ info.activeCallsign }} &emsp; {{ info.fullname }}</h1>
        <h1 v-else>Loading...</h1>
        <ul id="admin-links" v-if="info">
            <li><router-link :to="{name: 'profile', params: { callsign: info.activeCallsign }}">Profile</router-link></li>
            <li v-for="link in adminRoutes" :key="link.route">
                <router-link :to="{name: link.route, params: { userId: userId }}">{{ link.name }}</router-link>
            </li>
        </ul>
    </v-alert>
</template>

<style scoped>
.admin-info h1 {
  margin-left: 20px;
  padding-left: 0;
}
.admin-info ul {
  margin-left: 20px;
  padding-left: 0;
}
.admin-info ul li {
  display: inline;
}
.admin-info ul > li:not(:last-child)::after {
  content: " | ";
}
</style>

<script>
import axios from 'axios'

export default {
    name: "AdminUserInfo",
    data() {
        return { info: null };
    },
    computed: {
        isUserSupport: {
            get() {
                return this.$store.state.user.isUserSupport
            }
        },
        isLogManager: {
            get() {
                return this.$store.state.user.isLogManager
            }
        },
        adminRoutes: {
            get() {
                var routes = [];

                routes.push({ route: 'user_stats_admin', name: 'Stats' });
                routes.push({ route: 'user_awards_admin', name: 'Awards' });
                routes.push({ route: 'user_activations_admin', name: 'Activations' });
                routes.push({ route: 'user_logbook_admin', name: 'Logbook' });
                routes.push({ route: 'user_logs_admin', name: 'Logs' });
                routes.push({ route: 'user_account_admin', name: 'Account' });

                return routes;
            }
        },
        isUserAdminRoute: {
            get() {
                return this.$route.meta && this.$route.meta.admin && this.$route.params && this.$route.params.userId;
            }
        },
        userId: {
            get() {
                if (this.isUserAdminRoute)
                    return this.$route.params.userId;
                else
                    return null;
            }
        }
    },
    watch: {
        userId: function(newUserId) {
            if (!this.info || this.info.userId != newUserId)
                this.getUserInfo(newUserId);
        }
    },
    mounted() {
        if (this.userId)
            this.getUserInfo(this.userId);
    },
    methods: {
        getUserInfo(userId) {
            if (!userId)
                return;

            axios
                .get(`https://${process.env.VUE_APP_API_URL}/user/info?userId=${userId}`, this.$store.getters.authTokenHeader)
                .then(response => response.data)
                .then(json => this.info =  json);
        },
    }
};
</script>
