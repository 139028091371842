<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
    <v-btn v-on="on" v-if="!menu && !card" slot="activator" class="ma-2" :color="buttoncolor">Add Activation</v-btn>
    <v-card v-on="on" v-else-if="card" flat shaped class="activation-card">
      <v-card-text>
        <h1>Will you be at a park later?</h1>
        <v-icon size="100px">{{$vuetify.icons.values.mdiPlus}}</v-icon>
        <h1>Click here to add your activation!</h1>
      </v-card-text>
    </v-card>
    <v-list-item v-on="on" v-else>
        <v-list-item-action>
          <v-icon>{{ $vuetify.icons.values.mdiCalendarPlus }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Add Activation</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="headline green white--text" primary-title>
        <span>{{title}}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="formActivation" v-model="valid" lazy-validation>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6>
                <v-select label="Callsign"
                        v-model="activator"
                        :items="user_callsigns"
                        return-object
                        >
                </v-select>
              </v-flex>
              <v-flex xs12 sm6>
                <park-reference-entry v-model="reference" label="Reference" required></park-reference-entry>
              </v-flex>
              <v-flex xs12 sm6>
                <!--<v-text-field v-model="startdate" label="Start Date (UTC)" :rules="startdateRules" required></v-text-field>-->
                <v-menu
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startdate"
                      label="Start Date (UTC)"
                      :prepend-icon="$vuetify.icons.values.mdiCalendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="startdateRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startdate"
                    @input="menuStartDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6>
                <!--<v-text-field v-model="starttime" label="Start Time (UTC)" :rules="starttimeRules" required></v-text-field>-->
                <v-menu
                  ref="menuStartTime"
                  v-model="menuStartTime"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="starttime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="starttime"
                      label="Start Time (UTC)"
                      :prepend-icon="$vuetify.icons.values.mdiClockTimeFourOutline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="starttimeRules"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menuStartTime"
                    v-model="starttime"
                    format="24hr"
                    full-width
                    @click:minute="$refs.menuStartTime.save(starttime)"
                  ></v-time-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6>
                <!--<v-text-field v-model="enddate" label="End Date (UTC)" :rules="enddateRules" required></v-text-field>-->
                <v-menu
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="enddate"
                      label="End Date (UTC)"
                      :prepend-icon="$vuetify.icons.values.mdiCalendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="enddateRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="enddate"
                    @input="menuEndDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6>
                <!--<v-text-field v-model="endtime" label="End Time (UTC)" :rules="endtimeRules" required></v-text-field>-->
                <v-menu
                  ref="menuEndTime"
                  v-model="menuEndTime"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="endtime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endtime"
                      label="End Time (UTC)"
                      :prepend-icon="$vuetify.icons.values.mdiClockTimeFourOutline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="endtimeRules"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menuEndTime"
                    v-model="endtime"
                    format="24hr"
                    full-width
                    @click:minute="$refs.menuEndTime.save(endtime)"
                  ></v-time-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field v-model="frequency" label="Frequency"></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="comments" label="Comments"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <!--<small>All fields are required. Dates are expected in YYYY/MM/DD format. Times are expected in HH:MM format.</small>-->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">Cancel</v-btn>
        <v-btn color="green white--text" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import ParkReferenceEntry from '@/components/ParkReferenceEntry'

export default {
  name: 'ActivationForm',
  components: { ParkReferenceEntry },
  props: {
    title: { default: "Add Activation" },
    buttoncolor: { default: "orange accent-1 black--text" },
    buttonicon: { default: "" },
    headingcolor: { default: "" },
    card: { type: Boolean, default: false },
    menu: { type: Boolean, default: false },
    pactivator: { default: "" },
    pfrequency: { default: "" },
    preference: { default: "" },
    pcomments: { default: "" }
  },
  data: function () {
    return {
      dialog: false,
      valid: true,
      activator: (this.pactivator == '' ? this.$store.state.config.callsign : this.pactivator),
      frequency: this.pfrequency,
      reference: this.preference,
      comments: this.pcomments,
      startdate: '',
      starttime: '',
      enddate: '',
      endtime: '',
      // no need to validate activator as logged in user callsign will be used serverside
      startdateRules: [
        v => !!v || 'Start Date is required',
        v => /^\d{4}[/-]\d{1,2}[/-]\d{1,2}$/.test(v) || 'Format: YYYY/MM/DD'
      ],
      starttimeRules: [
        v => !!v || 'Start Time is required',
        v => /^\d{1,2}:\d{2}$/.test(v) || 'Format: HH:MM'
      ],
      enddateRules: [
        v => !!v || 'End Date is required',
        v => /^\d{4}[/-]\d{1,2}[/-]\d{1,2}$/.test(v) || 'Format: YYYY/MM/DD'
      ],
      endtimeRules: [
        v => !!v || 'End Time is required',
        v => /^\d{1,2}:\d{2}$/.test(v) || 'Format: HH:MM'
      ],
      frequencyRules: [
        v => !!v || 'Frequency (kHz) required',
        v => /^[\d.]+$/.test(v) || 'Example: 7123 or 14234',
        v => parseInt(v) > 1000 || 'Frequency in kHz (> 1000)'
        // v => /[\d.]+/.test(v) || 'Frequency (kHz), ex 14150'
      ],
      referenceRules: [
        v => !!v || 'Example: US-0001',
        v => this.$store.state.global.validReferenceRegex.test(v) || v == 'K-TEST' || 'Example: US-0001',
      ],
      commentsRules: [
        v => !!v || 'Comment is required',
      ],
      menuStartDate: false,
      menuStartTime: false,
      menuEndDate: false,
      menuEndTime: false
    }
  },
  computed: {
    isAuthenticated: {
      get() {
        return this.$store.state.user.isAuthenticated
      }
    },
    user_callsigns: {
      get() {
        return this.$store.state.user.callsigns
      }
    },
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.load()
      }
    }
  },
  methods: {
    load() { 
      this.activator = (this.pactivator == '' ? this.$store.state.config.callsign : this.pactivator)
      this.frequency = this.pfrequency
      this.reference = this.preference
      this.comments = this. pcomments
    },
    save () {
      if (this.$refs.formActivation.validate()) {
        // Native form submission is not yet supported
        const formData = {
          activator: this.activator,
          reference: this.reference,
          startDate: this.startdate,
          startTime: this.starttime,
          endDate: this.enddate,
          endTime: this.endtime,
          frequency: this.frequency,
          comments: this.comments
        }

        axios
          .post(`https://${process.env.VUE_APP_API_URL}/activation`, formData, this.$store.getters.authTokenHeader)
          .then((response) => {
              this.$store.commit('SET_ACTIVATIONS', response.data) // update activations with POST response data
            })
          .catch(err => {
              this.$dialog.error({ text: `${err.response.data}` })
            })

        this.$refs.formActivation.reset()
        this.dialog = false
      }
    },
    close () {
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.activation-card {
  text-align: center;
  border-radius: 40px;
}
.activation-card h1 {
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
