import Vue from 'vue'
import Vuex from 'vuex'

import activations from './modules/activations'
import activators from './modules/activators'
import hunters from './modules/hunters'
import spots from './modules/spots'
import totals from './modules/totals'
import global from './modules/global'
import config from './modules/config'
import parks from './modules/parks'
import user from './modules/user'
import map from './modules/map'
import adif from './modules/adif'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    itemsPerPage: 25
  },
  modules: {
    activations,
    activators,
    hunters,
    spots,
    totals,
    global,
    config,
    parks,
    user,
    map,
    adif
  },
  getters: {
    authTokenHeader: state => {
      return { headers: { 'Authorization': state.user.token } }
    },
    isParkAdmin: (state) => (prefix) => {
      return state.user.isTechAdmin || state.user.groups.includes('Park Administrator') || state.user.groups.includes('Park Manager ' + prefix)
    },
    isParkCreator: (state) => {
      return state.user.isTechAdmin || state.user.groups.includes('Park Administrator') || state.user.groups.includes('Park Creator')
    }
  },
})
