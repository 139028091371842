import axios from 'axios'

export default {
  state: {
    data: [],
    loading: true
  },

  mutations: {
    SET_TOTALS (state, totals) {
      state.data = totals
      state.loading = false
    },
    START_LOADING (state) {
      state.loading = true
    }
  },

  actions: {
    getTotals ({ commit }) {
      commit('START_LOADING')
      axios
        .get(`https://${process.env.VUE_APP_API_URL}/totals`)
        .then(response => response.data)
        .then(json => commit('SET_TOTALS', json))
    }
  }
}
