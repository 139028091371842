export default {
  state: {
    enablePoints: false,
    enablePause: true,
    validTimeRegex: /^(?:[0-2]\d):(?:[0-5]\d)$/i,
    validCallsignRegex: /^(?:[A-Z\d]{1,4}\/)?[A-Z\d]{1,3}\d[A-Z\d]*(?:\/[A-Z\d]{1,4})?$/i,
    validNakedCallsignRegex: /^[A-Z\d]{1,3}\d[A-Z\d]*$/i,
    validReferenceRegex: /^[A-Z0-9]{1,2}-[0-9]{4,5}$/,
    validEmailRegex: /^\S+@\S+\.\S+$/,
  },

  mutations: {
  },

  actions: {
  }
}
