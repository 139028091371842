import axios from 'axios'

export default {
  state: {
    data: [],
    loading: true,
    parks: {}
  },

  mutations: {
    SET_PARK (state, park) {
      var newpark = park[0]
      var newparkid = newpark.reference
      state.parks[newparkid] = newpark
    },
    SET_PARKS (state, parks) {
      state.data = parks
      state.loading = false
    },
    START_LOADING (state) {
      state.loading = true
    }
  },

  actions: {
    getPark ({ commit, state }, reference) {
      reference = reference.reference
      if (!(reference in state.parks)) {
        axios
          .get(`https://${process.env.VUE_APP_API_URL}/park/${reference}`)
          .then(response => response.data)
          .then(json => commit('SET_PARK', json))
      }
    },
    getParks ({ commit }) {
      commit('START_LOADING')
      axios
        .get(`https://${process.env.VUE_APP_API_URL}/park/all`)
        .then(response => response.data)
        .then(json => commit('SET_PARKS', json))
    }
  }
}
