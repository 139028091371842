<template>
  <v-app>

    <!-- -------- App Bar ----------------------------------------------------------------- -->
    <v-app-bar flat dark :color="isAdminRoute ? 'red' : 'primary'" app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

      <v-avatar :size="38">
        <img src="//static.pota.app/pota-logo-38x38.png" alt="Parks on the Air" />
      </v-avatar>
      <v-toolbar-title>{{ $route.meta.title }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-autocomplete
        label="Search for Callsigns &amp; Parks"
        v-model="lookupSelected"
        :items="lookupItems"
        :loading="loadingLookup"
        :search-input.sync="lookupSearch"
        item-text="display"
        :prepend-inner-icon="$vuetify.icons.values.mdiMagnify"
        return-object
        auto-select-first
        hide-details
        hide-no-data
        cache-items
        solo-inverted
        v-if="!$vuetify.breakpoint.mobile">
      </v-autocomplete>

      <v-spacer></v-spacer>

      <v-btn class="d-none d-lg-flex" :to="item.path" text v-for="item in topLinks" :key="item.url" tile router>
        <v-icon left>{{ item.meta.icon }}</v-icon> {{ item.meta.toolbar_title }}
      </v-btn>
      <v-btn class="d-none d-sm-flex d-lg-none" :to="item.path" text v-for="item in topLinks" :key="item.url" tile router>
        <v-icon left>{{ item.meta.icon }}</v-icon>
      </v-btn>

      <v-menu open-on-hover offset-y v-if="signedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" elevation="0" v-bind="attrs" v-on="on">
            <v-avatar left size="24" v-if="stats && isAccountActive">
              <v-img width=24 height=24 :src="`https://www.gravatar.com/avatar/${stats.gravatar}?s=24&d=identicon`"></v-img>
            </v-avatar>
            <v-icon left v-else>{{$vuetify.icons.values.mdiAccountCircle}}</v-icon>
            <span class="pl-2">{{callsign}}</span>
          </v-btn>
        </template>
        <v-card>
          <v-list dense>
            <v-list-item :to="`/profile/${callsign}`" v-if="isAccountActive">
              <v-list-item-title>View Profile</v-list-item-title>
            </v-list-item>
            <v-list-item :to="`/user/awards`" v-if="isAccountActive">
              <v-list-item-title>My Awards</v-list-item-title>
            </v-list-item>
            <v-list-item :to="`/user/stats`" v-if="isAccountActive">
              <v-list-item-title>My Stats</v-list-item-title>
            </v-list-item>
            <v-list-item :to="`/user/activations`" v-if="isAccountActive">
              <v-list-item-title>My Activations</v-list-item-title>
            </v-list-item>
            <v-list-item :to="`/user/logbook`" v-if="isAccountActive">
              <v-list-item-title>My Hunter Log</v-list-item-title>
            </v-list-item>
            <v-list-item :to="`/user/logs`" v-if="isAccountActive">
              <v-list-item-title>My Log Uploads</v-list-item-title>
            </v-list-item>
            <v-list-item :to="`/user/account`" v-if="isAccountActive">
              <v-list-item-title>My Account</v-list-item-title>
            </v-list-item>
            <v-list-item v-on:click="doSignOut">
              <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <template v-else>
        <v-btn color="orange" to="/signup" tile>
          <v-icon left>{{$vuetify.icons.values.mdiAccountPlusOutline}}</v-icon> Sign Up
        </v-btn>
        <v-btn v-on:click="doSignIn" tile>
          <v-icon left>{{$vuetify.icons.values.mdiLoginVariant}}</v-icon> Sign In
        </v-btn>
      </template>
    </v-app-bar>

    <!-- -------- NAV DRAWER ----------------------------------------------------------------- -->
    <v-navigation-drawer v-model="drawer" temporary app>
      <v-list>
        <v-list-item>
          <v-avatar :size="38">
            <img src="/pota-logo.png">
          </v-avatar>
          <v-list-item-title class="title pl-3 primary--text text--darken-1">
            Parks on the Air
          </v-list-item-title>
        </v-list-item>
      
        <template v-if="signedIn">
          <v-list-item two-line :to="`/profile/${callsign}`">
            <v-list-item-avatar v-if="stats && isAccountActive">
              <v-img :src="`https://www.gravatar.com/avatar/${stats.gravatar}?s=100&d=identicon`"></v-img>
            </v-list-item-avatar>

            <v-list-item-content v-if="isAccountActive">
              <v-list-item-title>{{ callsign }}</v-list-item-title>
              <v-list-item-subtitle>View Profile</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item v-if="$vuetify.breakpoint.mobile">
          <v-autocomplete
            label="Search for Callsigns &amp; Parks"
            v-model="lookupSelected"
            :items="lookupItems"
            :loading="loadingLookup"
            :search-input.sync="lookupSearch"
            item-text="display"
            :prepend-inner-icon="$vuetify.icons.values.mdiMagnify"
            return-object
            auto-select-first
            hide-no-data
            single-line>
          </v-autocomplete>
        </v-list-item>
        <v-divider class="py-1" v-else></v-divider>

        <template v-if="signedIn">
          <spot-form menu requireComment />
          <activation-form menu />
        </template>
        <!--<template v-else>
          <v-list-item to="/signup">
            <v-list-item-action>
              <v-icon>{{ $vuetify.icons.values.mdiAccountPlusOutline }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Sign Up</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="doSignIn">
            <v-list-item-action>
              <v-icon>{{ $vuetify.icons.values.mdiLoginVariant }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Sign In</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>-->

        <v-divider class="py-1"></v-divider>

        <v-list-item v-for="item in leftLinks" :key="item.path" :to="item.path" router>
          <v-list-item-action>
            <v-icon>{{ item.meta.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.meta.navbar_title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-if="signedIn">
          <v-divider class="py-1"></v-divider>

          <v-list-item :to="`/user/awards`" v-if="isAccountActive">
            <v-list-item-action>
              <v-icon>{{ $vuetify.icons.values.mdiLicense }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>My Awards</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="`/user/stats`" v-if="isAccountActive">
            <v-list-item-action>
              <v-icon>{{ $vuetify.icons.values.mdiGauge }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>My Stats</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="`/user/activations`" v-if="isAccountActive">
            <v-list-item-action>
              <v-icon>{{ $vuetify.icons.values.mdiRadioTower }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>My Activations</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="`/user/logbook`" v-if="isAccountActive">
            <v-list-item-action>
              <v-icon>{{ $vuetify.icons.values.mdiNotebookOutline }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>My Hunter Log</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="`/user/logs`" v-if="isAccountActive">
            <v-list-item-action>
              <v-icon>{{ $vuetify.icons.values.mdiFileUploadOutline }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>My Log Uploads</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="`/user/account`" v-if="isAccountActive">
            <v-list-item-action>
              <v-icon>{{ $vuetify.icons.values.mdiCardAccountDetailsOutline }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>My Account</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="doSignOut">
            <v-list-item-action>
              <v-icon>{{ $vuetify.icons.values.mdiLogoutVariant }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-if="isParkCreator">
          <v-divider class="py-1"></v-divider>

          <v-list-item :to="`/park/new`">
            <v-list-item-action>
              <v-icon>{{ $vuetify.icons.values.mdiMapMarkerPlusOutline }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>New Park</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider class="py-1"></v-divider>

        <v-list-item href="https://docs.pota.app" target="_blank">
          <v-list-item-action>
            <v-icon>{{ $vuetify.icons.values.mdiBookOpenVariant }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Rules &amp; Documentation</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="https://docs.pota.app/docs/privacy.html" target="_blank">
          <v-list-item-action>
            <v-icon>{{ $vuetify.icons.values.mdiFileDocumentOutline }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Privacy Policy</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="py-1"></v-divider>

        <v-list-item dense>
          <v-list-item-content>
              <v-switch
                v-model="darkMode"
                hide-details
                dense
                label="Late Shift Mode"
                class="ml-3">
              </v-switch>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-content>
              <v-switch 
                v-model="tableView"
                hide-details 
                dense 
                label="Prefer Table View" 
                class="ml-3">
              </v-switch>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-content>
              <v-switch 
                v-model="userStats"
                hide-details 
                dense 
                label="Show User Stats" 
                class="ml-3">
              </v-switch>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense v-if="isAlphaUser">
          <v-list-item-content>
              <v-switch 
                v-model="notHunted"
                hide-details 
                dense 
                label="Show Not Hunted" 
                class="ml-3">
              </v-switch>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-content>
              <v-switch 
                v-model="highlightNewSpots"
                hide-details 
                dense
                label="Highlight New Spots" 
                class="ml-3">
              </v-switch>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="py-1"></v-divider>

        <v-list-item>
          <v-list-item-content>
            <a href="https://parksontheair.com" target="_new">ParksOnTheAir.com</a>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            {{build_info}}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- -------- PAGE CONTENT ----------------------------------------------------------------- -->
    <v-main
       v-touch="{
         left: () => swipe('Left'),
         right: () => swipe('Right'),
         up: () => swipe('Up'),
         down: () => swipe('Down')
       }">
      <div v-if="motd" class="v-alert v-alert--prominent bg-info v-alert--density-default v-alert--variant-flat" role="alert" style="background-color: rgb(33, 150, 243)">
        <div class="v-alert__content" style="text-align: center;" v-html="motd"></div>
      </div>
      <v-container fluid grid-list-lg text-xs-center>
        <admin-user-info/>

        <router-view/>
      </v-container>
    </v-main>

    <!-- -------- PAGE FOOTER ----------------------------------------------------------------- -->
    <v-footer color="primary" dark app absolute>
      <cookie-law theme="royal">
        <div slot="message">
          This website uses cookies to provide you with the best user experience possible. See our <a href="https://pota.app/#/privacy" target="_blank">Privacy and Cookies</a> policy for more information.
        </div>
      </cookie-law>
      <v-layout justify-center align-center>
        <span>Copyright &copy; 2018-{{new Date().getFullYear()}} Parks on the Air®</span>
      </v-layout>
    </v-footer>

    <account-status v-if="currentRouteName != 'signup'"></account-status>
  </v-app>
</template>


<script>
import Vue from 'vue'
import CookieLaw from 'vue-cookie-law'
import { Auth } from 'aws-amplify'
import axios from 'axios'

import AccountStatus from '@/components/AccountStatus'
import ActivationForm from '@/components/ActivationForm'
import SpotForm from '@/components/SpotForm'
import AdminUserInfo from '@/components/AdminUserInfo'

export default {
  name: 'App',

  computed: {
    tableView: {
      get () {
        return this.$store.state.config.tableView
      },
      set (value) {
        this.$store.commit('SET_TABLE_VIEW', value);
      }
    },
    darkMode: {
      get () {
        return this.$store.state.config.darkMode
      },
      set (value) {
        this.$vuetify.theme.dark = value;
        this.$store.commit('SET_DARK_MODE', value);
      }
    },
    userStats: {
      get () {
        return this.$store.state.config.userStats
      },
      set (value) {
        this.$store.commit('SET_USER_STATS', value);
      }
    },
    notHunted: {
      get () {
        return this.$store.state.config.notHunted
      },
      set (value) {
        this.$store.commit('SET_NOT_HUNTED', value);
      }
    },
    highlightNewSpots: {
      get () {
        return this.$store.state.config.highlightNewSpots
      },
      set (value) {
        this.$store.commit('SET_HIGHLIGHT_NEW_SPOTS', value);
      }
    },
    callsign: {
      get () {
        return this.$store.state.user.callsign
      },
      set (value) {
        this.$store.commit('SET_CALLSIGN', value);
      }
    },
    email: {
      get () {
        return this.$store.state.user.email
      },
      set (value) {
        this.$store.commit('SET_EMAIL', value);
      }
    },
    name: {
      get () {
        return this.$store.state.user.name
      },
      set (value) {
        this.$store.commit('SET_NAME', value);
      }
    },
    newUserStatus: {
      get () {
        return this.$store.state.user.newUserStatus
      }
    },
    groups: {
      get () {
        return this.$store.state.user.groups
      },
      set (value) {
        this.$store.commit('SET_GROUPS', value);
      }
    },
    signedIn: {
      get () {
        return this.$store.state.user.isAuthenticated
      }
    },
    bandFilter: {
      get () {
        return this.$store.state.config.bandFilter
      },
      set (value) {
        this.$store.commit('SET_BAND_FILTER', value);
      }
    },
    modeFilter: {
      get () {
        return this.$store.state.config.modeFilter
      },
      set (value) {
        this.$store.commit('SET_MODE_FILTER', value);
      }
    },
    programFilter: {
      get () {
        return this.$store.state.config.programFilter
      },
      set (value) {
        this.$store.commit('SET_PROGRAM_FILTER', value);
      }
    },
    qrtFilter: {
      get () {
        return this.$store.state.config.qrtFilter
      },
      set (value) {
        this.$store.commit('SET_QRT_FILTER', value);
      }
    },
    huntedFilter: {
      get () {
        return this.$store.state.config.huntedFilter
      },
      set (value) {
        this.$store.commit('SET_HUNTED_FILTER', value);
      }
    },
    spotSort: {
      get () {
        return this.$store.state.config.spotSort
      },
      set (value) {
        this.$store.commit('SET_SORT', value);
      }
    },
    activationsPeriod: {
      get () {
        return this.$store.state.config.activationsPeriod
      },
      set (value) {
        this.$store.commit('SET_ACTIVATIONS_PERIOD', value);
      }
    },
    activationsSort: {
      get () {
        return this.$store.state.config.activationsSort
      },
      set (value) {
        this.$store.commit('SET_ACTIVATIONS_SORT', value);
      }
    },
    showRbnInHistory: {
      get () {
        return this.$store.state.config.showRbnInHistory
      },
      set (value) {
        this.$store.commit('SET_SHOW_RBN', value);
      }
    },
    stats: {
      get () {
        return this.$store.state.user.stats
      }
    },
    isAccountActive: {
      get () {
        return this.$store.state.user.accountStatus == 'active'
      }
    },
    currentRouteName: {
      get () {
        return this.$route.name
      }
    },
    topLinks: function() {
      let links = this.$router.options.routes.filter((route) => {
        return route.meta.display_top && this.isInGroup(route.meta.groups);
      })

      return links
    },
    leftLinks: function() {
      let links = this.$router.options.routes.filter((route) => {
        return route.meta.display_left && this.isInGroup(route.meta.groups);
      })

      return links
    },
    isAdminRoute: {
      get() {
        if (this.$route.meta && this.$route.meta.admin)
          return true
        return false
      }
    },
    isParkCreator: {
      get() {
        return this.$store.getters.isParkCreator
      }
    },
    isAlphaUser: {
      get() {
        return this.$store.state.user.isAlphaUser
      }
    },
    isBetaUser: {
      get() {
        return this.$store.state.user.isBetaUser
      }
    },
    isLogUploader: {
      get() {
        return this.$store.state.user.isLogUploader
      }
    }
  },

  components: {
    CookieLaw,
    AccountStatus,
    ActivationForm,
    SpotForm,
    AdminUserInfo
  },

  data () {
    return {
      build_info: `Build: ${process.env.VUE_APP_BUILD_INFO}`,
      drawer: false,
      signup: false,
      searchid: '',
      // tableView: false,
      routes: this.$router.options.routes,
      loadingLookup: false,
      lookupSearch: '',
      lookupItems: [],
      lookupSelected: null,
      motd: null
    }
  },

  methods: {
    swipe: function (direction) {
      if (direction == 'Down') {
        this.$store.dispatch('getSpots')
      }
    },

    onEnter: function (event) {
      if (event) {
        this.$router.push({ name: 'park', params: { id: event.target.value } })
      }
    },

    doSignIn: function () {
      Auth.federatedSignIn();
    },

    doSignOut: function () {
      Auth.signOut();
    },

    isInGroup: function (group) {
      if (group == null)
        return true // null means authentication is not required

      if (!this.signedIn)
        return false

      if (Array.isArray(group))
      {
        if (group.length == 0)
          return true // emptry array means any authenticated user

        for (var i = 0; i < group.length; i++)
        {
          if (this.$store.state.user.groups.includes(group[i]))
            return true
        }
      }
      else
      {
        return this.$store.state.user.groups.includes(group)
      }
      
      return false
    },

    lookup: function() {
      // cancel pending call
      clearTimeout(this._timerLookup)

      // delay new call
      this._timerLookup = setTimeout(() => {
        if (!this.lookupSearch || this.lookupSearch == '')
        {
          this.lookupItems = [];
          return;
        }

        this.loadingLookup = true;

        var params = '';

        params += `?search=${encodeURIComponent(this.lookupSearch)}`;
        params += `&size=10`;

        axios
          .get(`https://${process.env.VUE_APP_API_URL}/lookup${params}`)
          .then(response => response.data)
          .then(json => {
            if (json == null)
              json = []
            this.lookupItems = json;
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.loadingLookup = false;
          });
      }, 200)
    },

    refreshAuthToken: function() {
      clearTimeout(this._timerRefreshAuthToken)

      this.$store.dispatch('updateAuthToken');

      this._timerRefreshAuthToken = setInterval(() => {
        this.$store.dispatch('updateAuthToken');
      }, 60000);
    },

    checkMotd: function() {
      axios
          .get(`https://${process.env.VUE_APP_API_URL}/motd`)
          .then(response => {
            if (response.data && response.data.length >= 1 && response.data[0].html) {
              this.motd = response.data[0].html;
            }
          })
          .catch(err => {
            console.log(err);
          });
    },
  },

  watch: {
    lookupSearch: {
      handler () {
        this.lookup();
      },
    },
    lookupSelected: {
      handler () {
        if (this.lookupSelected)
        {
          if (this.lookupSelected.type == 'park')
            this.$router.push({ name: 'park', params: { id: this.lookupSelected.value } });
          else if (this.lookupSelected.type == 'user')
          {
            if (this.isAdminRoute)
            {
              this.$router.push({ name: this.$route.name, params: { userId: this.lookupSelected.id } });
              this.$router.go(0);
            }
            else
              this.$router.push({ name: 'profile', params: { callsign: this.lookupSelected.value } });
          }
          this.lookupItems = [];
          this.lookupSearch = '';
        }
      },
    },
  },

  beforeCreate() {
    // initialize settings from cookie if available... (cookie set in store)
    var settings = Vue.$cookies.get('POTA_SETTINGS');
    if (settings != null)
    {
      try {
        this.tableView = settings.tableView;
        this.darkMode = settings.darkMode;
        this.userStats = settings.userStats;
        this.bandFilter = settings.bandFilter;
        this.modeFilter = settings.modeFilter;
        this.programFilter = settings.programFilter;
        this.qrtFilter = settings.qrtFilter;
        this.huntedFilter = settings.huntedFilter;
        this.activationsPeriod = settings.activationsPeriod;
        this.spotSort = settings.spotSort;
        this.activationsSort = settings.activationsSort;
        this.notHunted = settings.notHunted;
        this.highlightNewSpots = settings.highlightNewSpots;
        this.showRbnInHistory = settings.showRbnInHistory;
      } catch (err) {
        console.log(err);
      }
    }

    this.$store.dispatch('updateUserSession');
    this.$store.dispatch('getHunted');
  },

  mounted() {
    this.refreshAuthToken();
  },

  beforeMount() {
    this.checkMotd();
  },

}
</script>
