import Vue from 'vue'
import Router from 'vue-router'

// couldn't figure out how to use $vuetify in the router config...
import { mdiCalendar, mdiClipboardText, mdiTerrain, mdiCity, mdiEarth, mdiMap, mdiMapMarker, mdiNewBox, mdiAccountPlusOutline, mdiBookOpenVariant } from '@mdi/js'

Vue.use(Router);

const router = new Router({
  routes: [{
      path: '/',
      name: 'spots',
      component: () =>
        import ( /* webpackPreload: true */ /* webpackChunkName: "main" */ '@/views/Spots.vue'),
      meta: {
        title: "Parks on the Air",
        toolbar_title: "Spots",
        navbar_title: "Spots",
        icon: mdiMapMarker,
        display_top: true,
        display_left: true
      }
    },
    {
      path: '/mobile',
      name: 'mobile',
      component: () =>
        import ( /* webpackPreload: true */ /* webpackChunkName: "main" */ '@/views/Mobile.vue'),
      meta: {
        title: "Parks on the Air",
        toolbar_title: "Spots",
        navbar_title: "Spots",
        icon: mdiMapMarker,
        display_top: false,
        display_left: false
      }
    },
    {
      path: '/activations',
      name: 'activations',
      component: () =>
        import ( /* webpackPreload: true */ /* webpackChunkName: "main" */ '@/views/Activations.vue'),
      meta: {
        title: "Parks on the Air",
        toolbar_title: "Activations",
        navbar_title: "Activations",
        icon: mdiCalendar,
        display_top: true,
        display_left: true
      }
    },
    {
      path: '/activationsv1',
      name: 'activationsv1',
      component: () =>
        import ( /* webpackPreload: true */ /* webpackChunkName: "main" */ '@/views/Activationsv1.vue'),
      meta: {
        title: "Parks on the Air",
        toolbar_title: "Activations",
        navbar_title: "Activations",
        icon: mdiCalendar,
        display_top: false,
        display_left: false
      }
    },
    {
      path: '/stats',
      name: 'stats',
      component: () =>
        import ('@/views/Stats.vue'),
      meta: {
        title: "Stats",
        icon: mdiClipboardText,
        display_top: false,
        display_left: false,
        groups: ['Beta'] // only Beta users
      }
    },
    {
      path: '/map',
      name: 'map',
      component: () =>
        import ( /* webpackChunkName: "map" */ '@/views/Map.vue'),
      props: true,
      meta: {
        title: "Map",
        toolbar_title: "Map",
        navbar_title: "Map",
        icon: mdiMap,
        display_top: false,
        display_left: true
      }
    },
    {
      path: '/parklist/:preselectLocation',
      name: 'parklist_preselect',
      component: () =>
        import ( /* webpackChunkName: "alt" */ '@/views/ParkList.vue'),
      props: true,
      meta: {
        title: "Park List",
        toolbar_title: "Park List",
        navbar_title: "Park List",
        icon: mdiEarth,
        display_top: false,
        display_left: false
      }
    },
    {
      path: '/parklist',
      name: 'parklist',
      component: () =>
        import ( /* webpackChunkName: "alt" */ '@/views/ParkList.vue'),
      meta: {
        title: "Park List",
        toolbar_title: "Park List",
        navbar_title: "Park List",
        icon: mdiEarth,
        display_top: false,
        display_left: true
      }
    },
    {
      path: '/activators',
      name: 'activators',
      component: () =>
        import ( /* webpackChunkName: "alt" */ '@/views/Activators.vue'),
      meta: {
        title: "Top Activators",
        toolbar_title: "Top Activators",
        navbar_title: "Top Activators",
        icon: mdiTerrain,
        display_top: false,
        display_left: true
      }
    },
    {
      path: '/hunters',
      name: 'hunters',
      component: () =>
        import ( /* webpackChunkName: "alt" */ '@/views/Hunters.vue'),
      meta: {
        title: "Top Hunters",
        toolbar_title: "Top Hunters",
        navbar_title: "Top Hunters",
        icon: mdiCity,
        display_top: false,
        display_left: true
      }
    },
/*
    {
      path: '/whatsnew',
      name: 'whatsnew',
      component: () =>
        import ( / * webpackChunkName: "resources" * / '@/views/WhatsNew.vue'),
      meta: {
        title: "What's New",
        toolbar_title: "What's New",
        navbar_title: "What's New",
        icon: mdiNewBox,
        display_top: false,
        display_left: false
      }
    },
*/
    {
      path: '/events',
      name: 'events',
      beforeEnter(to, from, next) {
        window.location.replace('https://docs.pota.app/docs/events.html');
      },
      meta: {
        title: "Official Events",
        toolbar_title: "Official Events",
        navbar_title: "Official Events",
        icon: mdiCalendar,
        display_top: false,
        display_left: true
      }
    },
    {
      path: '/park/new',
      name: 'park_new',
      component: () =>
        import ( /* webpackChunkName: "admin" */ '@/views/ParkEditor.vue'),
      props: false,
      meta: {
        title: "New Park",
        toolbar_title: "New Park",
        navbar_title: "New Park",
        display_top: false,
        display_left: false
      }
    },
    {
      path: '/park/edit/:id',
      name: 'park_edit',
      component: () =>
        import ( /* webpackChunkName: "admin" */ '@/views/ParkEditor.vue'),
      props: true,
      meta: {
        title: "Edit Park",
        toolbar_title: "Edit Park",
        navbar_title: "Edit Park",
        display_top: false,
        display_left: false
      }
    },
    {
      path: '/park/:id',
      name: 'park',
      component: () =>
        import ( /* webpackChunkName: "alt" */ '@/views/Park.vue'),
      props: true,
      meta: {
        title: "Park Information",
        toolbar_title: "Park Information",
        navbar_title: "Park Information",
        display_top: false,
        display_left: false
      }
    },
    {
      path: '/profile/:callsign',
      name: 'profile',
      component: () =>
        import ( /* webpackChunkName: "alt" */ '@/views/Profile.vue'),
      props: true,
      meta: {
        title: "Profile",
        toolbar_title: "Profile",
        navbar_title: "Profile",
        display_top: false,
        display_left: false
      }
    },
    {
      path: '/user/account/:userId',
      name: 'user_account_admin',
      component: () =>
        import ( /* webpackChunkName: "user" */ '@/views/User.vue'),
      props: true,
      meta: {
        title: "User Account",
        toolbar_title: "User Account",
        navbar_title: "User Account",
        display_top: false,
        display_left: false,
        admin: true,
        groups: ['UserManager', 'TechAdmin', 'User Support']
      }
    },
    {
      path: '/user/account',
      name: 'user_account',
      component: () =>
        import ( /* webpackChunkName: "user" */ '@/views/User.vue'),
      props: true,
      meta: {
        title: "My Account",
        toolbar_title: "My Account",
        navbar_title: "My Account",
        display_top: false,
        display_left: false,
        groups: [] // empty array means any authenticated user
      }
    },
    {
      path: '/user/awards/:userId',
      name: 'user_awards_admin',
      component: () =>
        import ( /* webpackChunkName: "user" */ '@/views/UserAwards.vue'),
      props: true,
      meta: {
        title: "User Awards",
        toolbar_title: "User Awards",
        navbar_title: "User Awards",
        display_top: false,
        display_left: false,
        admin: true,
        groups: ['UserManager', 'TechAdmin', 'User Support']
      }
    },
    {
      path: '/user/awards',
      name: 'user_awards',
      component: () =>
        import ( /* webpackChunkName: "user" */ '@/views/UserAwards.vue'),
      props: true,
      meta: {
        title: "My Awards",
        toolbar_title: "My Awards",
        navbar_title: "My Awards",
        display_top: false,
        display_left: false,
        groups: [] // empty array means any authenticated user
      }
    },
    {
      path: '/user/activations/:userId',
      name: 'user_activations_admin',
      component: () =>
        import ( /* webpackChunkName: "user" */ '@/views/UserActivations.vue'),
      props: true,
      meta: {
        title: "User Activations",
        toolbar_title: "User Activations",
        navbar_title: "User Activations",
        display_top: false,
        display_left: false,
        admin: true,
        groups: ['UserManager', 'TechAdmin', 'User Support']
      }
    },
    {
      path: '/user/activations',
      name: 'user_activations',
      component: () =>
        import ( /* webpackChunkName: "user" */ '@/views/UserActivations.vue'),
      props: true,
      meta: {
        title: "My Activations",
        toolbar_title: "My Activations",
        navbar_title: "My Activations",
        display_top: false,
        display_left: false,
        groups: [] // empty array means any authenticated user
      }
    },
    {
      path: '/user/logbook/:userId',
      name: 'user_logbook_admin',
      component: () =>
        import ( /* webpackChunkName: "user" */ '@/views/UserLogbook.vue'),
      props: true,
      meta: {
        title: "User Logbook",
        toolbar_title: "User Logbook",
        navbar_title: "User Logbook",
        display_top: false,
        display_left: false,
        admin: true,
        groups: ['UserManager', 'TechAdmin', 'User Support']
      }
    },
    {
      path: '/user/logbook',
      name: 'user_logbook',
      component: () =>
        import ( /* webpackChunkName: "user" */ '@/views/UserLogbook.vue'),
      props: true,
      meta: {
        title: "My Logbook",
        toolbar_title: "My Hunter Log",
        navbar_title: "My Hunter Log",
        display_top: false,
        display_left: false,
        groups: [] // empty array means any authenticated user
      }
    },
    {
      path: '/user/logs/:userId',
      name: 'user_logs_admin',
      component: () =>
        import ( /* webpackChunkName: "user" */ '@/views/UserLogUploads.vue'),
      props: true,
      meta: {
        title: "User Log Uploads",
        toolbar_title: "User Log Uploads",
        navbar_title: "User Log Uploads",
        display_top: false,
        display_left: false,
        admin: true,
        groups: ['UserManager', 'TechAdmin', 'Log Manager']
      }
    },
    {
      path: '/user/logs',
      name: 'user_logs',
      component: () =>
        import ( /* webpackChunkName: "user" */ '@/views/UserLogUploads.vue'),
      props: true,
      meta: {
        title: "My Log Uploads",
        toolbar_title: "My Log Uploads",
        navbar_title: "My Log Uploads",
        display_top: false,
        display_left: false,
        groups: [] // empty array means any authenticated user
      }
    },
    {
      path: '/user/mle',
      name: 'user_mle',
      component: () =>
        import ( /* webpackChunkName: "user" */ '@/views/ManualLogEntry.vue'),
      props: true,
      meta: {
        title: "Manual Log Entry",
        toolbar_title: "Manual Log Entry",
        navbar_title: "Manual Log Entry",
        display_top: false,
        display_left: false,
        groups: [] // empty array means any authenticated user
      }
    },
    {
      path: '/user/stats/:userId',
      name: 'user_stats_admin',
      component: () =>
        import ( /* webpackChunkName: "user" */ '@/views/UserStats.vue'),
      props: true,
      meta: {
        title: "User Stats",
        toolbar_title: "User Stats",
        navbar_title: "User Stats",
        display_top: false,
        display_left: false,
        admin: true,
        groups: ['UserManager', 'TechAdmin', 'User Support']
      }
    },
    {
      path: '/user/stats',
      name: 'user_stats',
      component: () =>
        import ( /* webpackChunkName: "user" */ '@/views/UserStats.vue'),
      props: true,
      meta: {
        title: "My Stats",
        toolbar_title: "My Stats",
        navbar_title: "My Stats",
        display_top: false,
        display_left: false,
        groups: [] // empty array means any authenticated user
      }
    },
    {
      path: '/adifupload',
      name: 'adifupload',
      component: () =>
        import ( /* webpackChunkName: "adifupload" */ '@/views/AdifUpload.vue'),
      props: true,
      meta: {
        title: "ADIF Upload",
        toolbar_title: "ADIF Upload",
        navbar_title: "ADIF Upload",
        display_top: false,
        display_left: false,
        groups: ['Beta'] // only Beta users
      }
    },
    {
      path: '/signup',
      name: 'signup',
      component: () =>
        import ( /* webpackChunkName: "resources" */ '@/views/SignUp.vue'),
      meta: {
        title: "Sign Up",
        toolbar_title: "Sign Up",
        navbar_title: "Sign Up",
        icon: mdiAccountPlusOutline,
        display_top: false,
        display_left: false,
      }
    },
    {
      path: '/docs',
      name: 'docs',
      beforeEnter(to, from, next) {
        window.location.replace('https://docs.pota.app');
      },
      meta: {
        title: "Rules & Documentation",
        toolbar_title: "Docs",
        navbar_title: "Docs",
        icon: mdiBookOpenVariant,
        display_top: true,
        display_left: false,
      }
    }
  ]
})

export default router